import React from 'react';

import { useRouter } from 'next/router';

import SimpleBackdrop from '@client/components/general/SimpleBackdrop';

import { useAuth } from './AuthContext';

const PublicRoute = (props: {
  children: React.ReactNode;
  disabledRedirect?: boolean;
}): JSX.Element | React.ReactNode | any => {
  const router = useRouter();
  const { user, loading, previousProtectedRoute } = useAuth();

  if (loading) {
    return <SimpleBackdrop />;
  } else if (user) {
    if (!props.disabledRedirect) {
      router.push(previousProtectedRoute ?? '/');
      return <SimpleBackdrop />;
    } else {
      return props.children;
    }
  } else if (!user) {
    return props.children;
  } else {
    return <>{!user ? props.children : <SimpleBackdrop />}</>;
  }
};

export default PublicRoute;
