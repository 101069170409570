import React from 'react';
import { Controller, type RegisterOptions, useFormContext } from 'react-hook-form';

import {
  Box,
  type BoxProps,
  FormControl,
  FormHelperText,
  type TextFieldProps,
} from '@mui/material';
import TextField from '@mui/material/TextField';

import { useBreakpoints } from '@client/components/hooks/useBreakpoints';

import Label from '../helpers/Label';

type TextFieldInputProps = {
  name: string;
  defaultValue?: string;
  helperText?: string | React.ReactNode;
  bottomHelperText?: string | React.ReactNode;
  rules?: RegisterOptions;
  TextFieldProps?: Omit<TextFieldProps, 'name' | 'defaultValue'>;
  label?: React.ReactNode;
  inputContainerProps?: BoxProps;
  handleTransform?: (value: any) => any;
  disabled?: boolean;
};

const TextFieldInput = ({
  name,
  defaultValue,
  helperText,
  rules,
  TextFieldProps,
  label,
  inputContainerProps,
  bottomHelperText,
  handleTransform,
  disabled,
}: TextFieldInputProps) => {
  const { isMobile } = useBreakpoints();

  const form = useFormContext();
  return (
    <Box {...inputContainerProps}>
      <Controller
        defaultValue={defaultValue ?? ''}
        control={form.control}
        name={name}
        rules={rules}
        render={({ field, fieldState }) => {
          return (
            <FormControl fullWidth>
              {label && isMobile && <Label label={label} helperText={helperText} />}
              <TextField
                // sx={{ width: "100%", ":hover": { borderColor: "blue" } }}
                {...field}
                inputRef={field.ref}
                disabled={disabled}
                onChange={e =>
                  field.onChange(handleTransform ? handleTransform(e.target.value) : e.target.value)
                }
                error={fieldState.error ? true : false}
                label={!isMobile ? label : null}
                {...TextFieldProps}
              />
              {bottomHelperText && <FormHelperText>{bottomHelperText}</FormHelperText>}
              {fieldState.error && (
                <FormHelperText error>{fieldState.error?.message}</FormHelperText>
              )}
            </FormControl>
          );
        }}></Controller>
    </Box>
  );
};

export default TextFieldInput;
