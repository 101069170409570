import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, FormHelperText, FormLabel, type SxProps } from '@mui/material';

import { SimpleToolTip } from './SimpleToolTip';

type LabelProps = {
  label?: string | React.ReactNode;
  helperText?: string | React.ReactNode;
  tooltip?: string | React.ReactNode;
  tooltipIcon?: JSX.Element;
  sxProps?: SxProps;
  sx?: SxProps;
  children?: React.ReactNode;
};

export default ({ label, tooltip, tooltipIcon, helperText, sxProps, sx, children }: LabelProps) => {
  const icon = tooltipIcon ?? <InfoOutlinedIcon color='primary' fontSize='small' />;
  return (
    <FormLabel
      sx={sxProps || sx ? sxProps || sx : { mb: 1, display: 'flex', gap: 1, cursor: 'text' }}>
      <Box>
        {label || children}
        {helperText && <FormHelperText sx={{ p: 0, m: 0, mt: 1 }}>{helperText}</FormHelperText>}
      </Box>

      {tooltip && <SimpleToolTip title={tooltip}>{icon}</SimpleToolTip>}
    </FormLabel>
  );
};
