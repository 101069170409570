import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useAuth } from '@client/context/auth/AuthContext';

import { type User } from 'firebase/auth';

import { apiClient } from '../dataFetching/apiClient';

export const BootIntercom = ({ children }: { children: any }) => {
  const { boot, shutdown } = useIntercom();
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    restartIntercom();
  }, [isAuthenticated, user?.uid]);

  const restartIntercom = () => {
    if (user && user.email && user.uid) {
      bootMessenger(user);
    }
    // else {
    //   launchPublicChat();
    // }
  };

  const launchPublicChat = () => {
    shutdown();
    boot();
  };
  const bootMessenger = async (user: User) => {
    try {
      const response = await apiClient.get(`/api/users/${user.uid}/intercom/getUserHash`);

      const data = response?.data as { userHash: string };

      if (data && user.email) {
        boot({
          userId: user.uid,
          email: user.email,
          userHash: data.userHash,
        });
      } else {
        launchPublicChat();
      }
    } catch (error: any) {
      launchPublicChat();
    }
  };

  return children;
};
