import { type Breakpoint, useMediaQuery, useTheme } from '@mui/material';

export const useBreakpoints = () => {
  const theme = useTheme();

  const isSmallerThanBreakpoint = (breakpoint: Breakpoint) =>
    useMediaQuery(theme.breakpoints.down(breakpoint));

  const isLargerThanBreakpoint = (breakpoint: Breakpoint) =>
    useMediaQuery(theme.breakpoints.up(breakpoint));

  const isBetweenBreakpoints = (breakpoint1: Breakpoint, breakpoint2: Breakpoint) =>
    useMediaQuery(theme.breakpoints.between(breakpoint1, breakpoint2));

  const isOnlyBreakpoint = (breakpoint: Breakpoint) =>
    useMediaQuery(theme.breakpoints.only(breakpoint));

  const setCustomBreakpoint = (breakpoint: string, operation: 'min' | 'max') =>
    useMediaQuery(`(${operation}-width: ${breakpoint})`);

  const isMobile = isSmallerThanBreakpoint('sm');
  const isTablet = isBetweenBreakpoints('sm', 'md');
  const isDesktop = isLargerThanBreakpoint('md');

  return {
    isSmallerThanBreakpoint,
    isLargerThanBreakpoint,
    isBetweenBreakpoints,
    isOnlyBreakpoint,
    setCustomBreakpoint,
    isMobile,
    isTablet,
    isDesktop,
  };
};
