import React from 'react';

import { Fade, Tooltip, type TooltipProps, Zoom } from '@mui/material';
import { type TransitionProps } from '@mui/material/transitions';

type TransitionType = 'default' | 'fade' | 'zoom';

type Props = TooltipProps & {
  children: React.ReactNode;
  title: string | React.ReactNode;
  transitionComponent?: TransitionType;
  transitionProps?: TransitionProps;
};

const getTransitionComponent = (transition: TransitionType) => {
  switch (transition) {
    case 'fade':
      return Fade;
    case 'zoom':
      return Zoom;
    default:
      return undefined;
  }
};

export const SimpleToolTip = ({
  children,
  title,
  transitionComponent = 'default',
  transitionProps,
  ...props
}: Props) => {
  const TransitionComponent = getTransitionComponent(transitionComponent);
  return (
    <Tooltip
      {...props}
      TransitionComponent={TransitionComponent}
      TransitionProps={transitionProps}
      enterDelay={1}
      enterTouchDelay={1}
      leaveTouchDelay={500}
      leaveDelay={1}
      title={title}>
      {children}
    </Tooltip>
  );
};
