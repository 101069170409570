import { useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import { useMixpanelTracking } from '../hooks/useMixpanelTracking';

export const PageViewTracker = () => {
  const router = useRouter();
  const { trackPageView } = useMixpanelTracking();
  const isPageTrackedRef = useRef<boolean>(false);

  useEffect(() => {
    // console.log("PageViewTracker: useEffect ran", router.asPath);
    if (!isPageTrackedRef.current) {
      // console.log("Triggering mixpanel trackPageView", router.asPath);
      trackPageView(router.asPath);

      isPageTrackedRef.current = true;
    }
  }, []);

  return null; // This component doesn't render anything
};
