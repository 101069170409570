import React, { useState } from 'react';

import Image, { type ImageProps, type StaticImageData } from 'next/image';

type Props = {
  imageSrc: string | StaticImageData;
  alt: string;
  width?: number;
  height?: number;
  imageProps?: Partial<ImageProps>;
  shouldUseNaturalImageSizes?: boolean;
  onLoad?: () => void;
};
const ImageLoader = ({
  imageSrc,
  alt,
  width,
  height,
  shouldUseNaturalImageSizes,
  imageProps,
  onLoad,
}: Props) => {
  const [imageDimensions, setImageDimensions] = useState({
    width: 50,
    height: 50,
  });

  const handleImageLoad: React.ReactEventHandler<HTMLImageElement> = event => {
    setImageDimensions({
      width: event.currentTarget.naturalWidth,
      height: event.currentTarget.naturalHeight,
    });
    onLoad && onLoad();
  };

  return (
    <Image
      src={imageSrc}
      alt={alt}
      style={{ objectFit: 'contain' }}
      onLoad={handleImageLoad}
      priority={imageProps?.priority}
      width={shouldUseNaturalImageSizes ? imageDimensions.width : width}
      height={shouldUseNaturalImageSizes ? imageDimensions.height : height}
      loading={imageProps?.loading ?? 'lazy'}
      {...imageProps}
    />
  );
};

export default ImageLoader;
