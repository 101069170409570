import * as React from 'react';

import { Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default () => (
  <Box>
    <Backdrop sx={{ color: '#1f29bc', zIndex: theme => theme.zIndex.drawer + 1 }} open={true}>
      <CircularProgress color='inherit' />
    </Backdrop>
  </Box>
);
