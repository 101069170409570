import React from 'react';

import { Box, type SxProps } from '@mui/material';

import { sizes } from '@constants/sizes';

const FormSpacer = ({
  removePadding,
  children,
  sx,
}: {
  removePadding?: boolean;
  sx?: SxProps;
  children: React.ReactNode | React.ReactNode[];
}) => {
  const { formRowGap, formColumnGap } = sizes;
  return (
    <Box
      sx={{
        mt: 2,
        mb: 2,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        rowGap: formRowGap,
        columnGap: formColumnGap,
        padding: removePadding ? 0 : formRowGap,
        ...sx,
      }}>
      {children}
    </Box>
  );
};

export default FormSpacer;
